.date-picker .rdp-root {
  --rdp-nav-height: 40px;
  --rdp-day-width: 40px;
  --rdp-day_button-width: 40px;
  --rdp-day-height: 40px;
  --rdp-day_button-height: 40px;
  margin: 1em;
}

.date-picker .rdp-day_button {
  min-height: 40px;
}

.date-picker .date-button-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.date-picker-dialog-sheet {
  background-color: var(--white);
  border: 1px solid var(--border);
  z-index: 100;
}

.date-picker-dialog-sheet::before,
.date-picker-dialog-sheet::after {
  content: " ";
  position: absolute;
  width: 5px;
  border-style: solid;
}

.date-picker-dialog-sheet::before {
  border-width: 7px;
  left: 30px;
}

.date-picker-dialog-sheet::after {
  border-width: 5px;
  left: 32px;
}

.date-picker-dialog-sheet.bottom::before,
.date-picker-dialog-sheet.bottom::after {
  top: 100%; /* At the bottom of the tooltip */
}
.date-picker-dialog-sheet.bottom::before {
  margin-top: 1px;
  border-color: var(--border) transparent transparent transparent;
}
.date-picker-dialog-sheet.bottom::after {
  border-color: var(--white) transparent transparent transparent;
}

.date-picker-dialog-sheet.top::before,
.date-picker-dialog-sheet.top::after {
  bottom: 100%; /* At the top of the tooltip */
}
.date-picker-dialog-sheet.top::before {
  border-color: transparent transparent var(--border) transparent;
  margin-top: -1px;
}
.date-picker-dialog-sheet.top::after {
  border-color: transparent transparent var(--white) transparent;
}
