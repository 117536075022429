.site-footer {
  background-color: var(--darkest_accent);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.site-footer p,
.site-footer span,
.site-footer div {
  color: white;
}
.site-footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.site-footer .powered-by {
  text-align: center;
}
.site-footer .mmtc-logo {
  width: 300px;
}
.white-labeled .site-footer .mmtc-logo {
  width: 200px;
}
.site-footer .disclaimer p {
  font-size: 12px;
}
.site-footer .disclaimer a {
  font-size: 12px;
}

.funding {
  display: flex;
  flex-direction: column;
  align-items: column;
  justify-content: center;
  text-align: center;
}

.site-footer .container > * {
  margin: 2rem auto 0 auto;
}

.site-footer a,
.site-footer a:visited,
.site-footer a:hover {
  color: white;
}
.site-footer a:hover {
  opacity: 0.8;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.logos p {
  text-align: center;
  font-size: 16px;
  font-style: italic;
}
.logos img {
  width: 190px;
  margin: 1rem;
}
