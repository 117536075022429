.accordion h2,
.panel__label,
.panel__content {
  padding: 1rem 0;
  margin: 0;
}

button.panel__label {
  border-radius: 0;
  box-shadow: none;
  max-width: 100%;
}

.panel {
  background-color: white;
  color: var(--text_color);
  border-bottom: 1px solid var(--border);
  border-radius: 2px;
}
.panel:first-of-type {
  border-top: none;
}
.panel__label {
  position: relative;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-weight: 600;
  font-family: inherit;
  transition: color 0.2s linear;
  cursor: pointer;
  color: var(--text_color);
  padding-right: 4rem;
}
.panel__label:focus {
}
.panel__label:after,
.panel__label:before {
  content: "";
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 1.5rem;
  height: 2px;
  margin-top: -2px;
  background: var(--nav_color);
}
.panel__label:before {
  transform: rotate(-90deg);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel.active .panel__content {
  opacity: 1;
}
.panel.active .panel__label {
  /* // color: #957029; */
}
.panel.active .panel__label:before {
  transform: rotate(0deg);
}
.panel__inner {
  display: flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel__content {
  box-sizing: border-box;
  padding: 0 3rem 2rem 0;
  opacity: 0;
  transition: opacity 0.3s linear 0.18s;
}
.panel:not(:last-child) {
  margin-bottom: 3px;
}
