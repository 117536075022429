.teaser {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teaser-header {
  padding: 4rem;
  background: var(--dark_accent);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.teaser-headline {
  text-align: center;
  font-size: 48px;
  align-items: center;
  color: white;
  margin-top: 3rem;
  font-size: 10vw;
}
.pdf-img {
  width: 100%;
  max-width: 1200px;
}
.teaser-bug {
  max-width: 250px;
  width: 100%;
}

.test-journal-button {
  background-color: #fff;
  border: 2px solid var(--dark_accent);
  color: var(--dark_accent);
  font-weight: 600;
  padding: 1px;
  height: 55px;
}

.test-journal-banner {
  padding: 2.5rem 0 0 0;
  text-align: center;
}
.wizard.embedded .test-journal-banner {
  padding: 2rem 0;
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .wizard.embedded .test-journal-banner {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .test-journal-banner {
    margin-top: 1rem;
  }
}

.test-journal-banner .button {
  margin-bottom: 0;
}

/* .home-page #search-module{padding-top:1rem;} */

/* .hero {
  width: 100%;
  visibility: visible;
  background-size: cover;
  background-position: 70%;
  display:flex;
  height:100%;
}
.hero .section-text{background: rgba(255,255,255,.9)}
.hero .container{padding:0;}
.hero_logo{width: 70%;
  padding-bottom: 3rem;
  max-width: 200px;}
.hero h3{font-weight:500;}
.hero button,
.hero a.button {width:50%;z-index:2;}
.hero .section-image{
  margin-top:-4rem;
} */

/* 













.not-feeling-well .intro-p{
  max-width: 70rem;
  margin: 0 auto 2rem auto;
  text-align: center;
}
.not-feeling-well .icon-card p{
  margin-bottom:0;
}
.about-t3 {
  position:relative;top:-7rem;
  margin-bottom:-7rem;
}
.step-3-icons{
  text-align:center;
  justify-content: center;
  display:flex;
  align-items:center;
  width:100%;height:125px;
}
.step-3-icons .fas{font-size:32px;color:var(--dark_accent);margin:0 .5rem;}
.step-3-icons .fas.fa-arrow-right{font-size:16px;opacity:.5;}

@media screen and (min-width: 576px) {
  .hero .section-image{
    position: absolute;
    left:0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin-top:initial;
  }
  .hero img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .hero button,
  .hero a.button{width:100%;}
  .hero .section-image{margin-top:0;}
  .about-t3 {
    margin-bottom:inherit;
    top:0;
  }
}

@media screen and (min-width: 768px) {
  .hero{
    min-height:400px;
  }
} */
