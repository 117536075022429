@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&family=Noto+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
.big-label {
  clear: right;
  font-family: "Bitter", sans-serif;
  font-weight: 500;
  color: var(--text_color);
}

html {
  color: red;
}

p,
em,
strong,
label,
svg,
div {
  color: var(--text_color);
}

b,
strong {
  font-weight: 600;
}
em {
  font-style: italic;
  font-weight: 400;
}

a {
  color: var(--link_color);
  transition: var(--transition);
}

a:not(.button):visited {
  color: purple;
}
a:hover,
a:focus,
a:active {
  color: var(--activelink);
}

p,
ul,
ol,
li,
button,
a,
div {
  font-size: 16px;
  line-height: 150%;
}
nav div {
  font-size: 16px;
  line-height: 1.3;
}
h1,
.big-label {
  font-size: 28px;
  line-height: 140%;
  margin-bottom: var(--header-margin);
}
.embedded .requiredDetails .big-label,
.smaller-label.label {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 1rem 0;
}
.embedded .first-label {
  padding-left: 0;
}

@media screen and (min-width: 576px) {
  .testType01 .big-label,
  .testType02 .big-label {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .testType01 .big-label,
  .testType02 .big-label {
    margin-bottom: 2rem;
  }
}

h2 {
  font-size: 22px;
  line-height: 150%;
  margin-bottom: var(--header-margin);
}
.with-rule {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.with-rule:after {
  content: "";
  height: 3px;
  width: 30px;
  background: var(--main_accent);
  margin-top: 1rem;
}
.with-rule {
  margin-bottom: 1rem;
}

.to-lower-case {
  text-transform: lowercase;
}

h3 {
  font-size: 18px;
  line-height: 150%;
  margin-bottom: var(--header-margin);
  font-weight: 400;
}
ul,
ol {
  margin: 0 0 0 1em;
}
p,
ul {
  margin-bottom: 1.5rem;
}
li {
  margin-bottom: 1rem;
}
/* p:last-child,li:last-child{margin-bottom:0;} */
a,
.link-button {
  cursor: pointer;
  font-weight: 500;
}
label,
.label {
  margin-bottom: 0.5rem;
}

small {
  font-size: 75%;
}

.loud-text {
  color: var(--main_accent);
}
.loud-text-secondary {
  color: var(--green);
}

@media screen and (min-width: 768px) {
  p,
  ul,
  ol,
  li,
  button,
  a,
  div {
    font-size: 18px;
  }
}

footer i,
.sharing-module i {
  font-size: 40px;
  margin: 1rem;
}
.sharing-module .share-buttons {
  text-align: center;
}
