.testing-journal-pdf {
  width: 100%;
  height: 100%;
}

.testing-journal-header {
  padding: 17px;
  border-bottom: 1px solid var(--border);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 17px;
}
.testing-journal-header img {
  width: 105px;
}

.testing-journal-header h4 {
  margin: 1rem;
}

.testing-journal-page h2 {
  align-items: center;
}
.testing-journal-page h2.with-rule::after {
  margin-left: auto;
  margin-right: auto;
}

.testing-journal-page main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  gap: 2rem;
}
.testing-journal-page aside {
  background-color: #eeeeee;
  border-radius: 10px;
  padding: 20px;
}

.test-journal-footer {
  border-top: 2px solid var(--text_color);
  padding: 17px 0;
}

.test-journal-footer .disclaimer p {
  font-size: 12px;
}

.test-journal-logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.test-journal-logos img {
  width: auto;
  height: 38px;
}
