.attention-banner {
  flex-direction: column;
  color: var(--text_color);
}
.attention-banner .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-style: italic;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
}
.attention-banner img {
  margin-right: 1rem;
}
.attention-banner .logo {
  margin-right: 0;
}
.attention-banner a {
  text-decoration: none;
}
.attention-banner .language-switcher {
  margin-left: 30px;
}
.attention-banner .language-switcher .link-button,
.attention-banner .language-switcher i {
  color: var(--white);
}
#about-banner {
  flex-direction: column;
  text-align: center;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
#about-banner h1 {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 150%;
}
#about-banner,
.attention-banner {
  min-height: 58px;
}

.make-my-test-count-banner {
  background-color: var(--darkest_accent);
  color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-decoration: none;
  font-size: 0.95em;
  transition: var(--transition);
}
.make-my-test-count-banner a {
  color: var(--white);
  text-decoration: none;
}

.make-my-test-count-banner:hover,
.make-my-test-count-banner:visited {
  color: white;
}

.make-my-test-count-banner .powered-by,
.make-my-test-count-banner img {
  display: inline-block;
  flex-direction: row;
  display: flex;
}

.make-my-test-count-banner .powered-by span {
  margin-right: 15px;
}
.make-my-test-count-banner img {
  height: 30px;
  width: auto;
}
