/* LANGUAGE SWITCHER */
.language-switcher {
  display: flex;
  /* align-items: center; */
  position: relative;
  list-style: none;
  margin: 0 16px;
}

.language-switcher li {
  margin: 0;
}

.language-switcher .link-button {
  text-decoration: none;
  font-family: var(--serif-font-family);
  font-weight: 700;
  color: var(--text_color);
  font-size: 18px;
  padding: 0.125rem;
  line-height: 17.5px;
  border-radius: 0.25rem;
  min-height: 0;
  margin: 0;
}

.language-switcher .fas {
  font-size: 1.358rem;
  line-height: 150%;
  margin-right: 4px;
}
