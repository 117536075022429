.plugin-updates {
  max-width: 620px;
  margin: 0 auto;
  padding: 20px;
}
.plugin-updates h2 {
  margin-top: 20px;
}

.plugin-updates h4 {
  text-decoration: underline;
}
.plugin-update-content {
  padding-left: 20px;
}

code {
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: block;
  padding: 5px;
  margin-bottom: 10px;
  font-family: monospace;
  overflow-wrap: break-word;
}

p code {
  display: inline-block;
  margin-bottom: 0px;
}
