/* input:focus,
input:focus-within,
input:focus-visible,
input:active,
input:hover {
} */
input,
button,
select,
.button,
textarea {
  padding: 0 1.5rem;
  margin: 0 0.5rem 0.5rem 0;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* input:last-of-type,
button:last-of-type,.button:last-of-type,textarea:last-of-type{
margin:0;
}
input:first-of-type,
button:first-of-type,.button:first-of-type,textarea:first-of-type{
  margin:0 .5rem .5rem 0;
} */

::placeholder {
  color: #999;
}

.input-button input {
  /* display:none; */
  /* for screen readers?: */
  opacity: 0;
  position: absolute;
}

input + label {
  position: relative;
}

input,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
select,
textarea {
  color: var(--text_color);
  border: 1px solid var(--border);
  font-family: "Noto sans", sans-serif;
  font-size: inherit;
  border-radius: var(--sq_border_radius);
  height: var(--input-height);
}

input[type="date"] {
  background: white; /* fixes a bug where date inputs are grey on mobile */
}

input[type="checkbox"]:focus {
  outline: 2px solid var(--dark_accent);
}

button,
.button {
  cursor: pointer;
  background-color: var(--dark_accent);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border_radius);
  text-decoration: none;
  width: 100%;
  text-align: center;
  transition: all 0.5s ease;
  border: 0;
  min-height: var(--button-height);
  font-family: "Noto sans", sans-serif;
  font-weight: 400;
  line-height: 1.3;
  /* max-width:350px; */
  /* border: 2px solid var(--main_accent); */
  /* margin-top:1rem; */
}

button:hover,
.button:hover {
  /* background: white; */
  /* color: var(--main_accent); */
  transition: all 0.5s ease;
  box-shadow: none;
}
/* so the active/focus effect doesn't make it jank */
input,
button,
.button {
  border: 3px solid transparent;
  outline: 3px solid transparent;
}

button:focus,
button:active,
.button:focus,
.button:active {
  box-shadow: none;
  border: 3px solid white;
  outline: 3px solid var(--dark_accent);
  z-index: 1;
}

button.link-button {
  box-shadow: none;
  background: transparent;
  text-decoration: underline;
  color: var(--link_color);
}

.quiet-button {
  background: white;
  color: var(--text_color);
}
button.secondary,
.button.secondary {
  background: var(--secondary_accent);
  color: var(--text_color);
  /* border: 2px solid var(--text_color); */
}
button.tertiary,
.button.tertiary {
  background: white;
  color: var(--dark_accent);
  /* border: 2px solid var(--secondary_accent); */
}
/* button.secondary:hover,
.button.secondary:hover {
  color:white;
} */
.button.disabled,
button.disabled,
.button.disabled:hover,
button.disabled:hover,
.button.loading,
button.loading,
select.disabled,
select.disabled:hover,
.button.loading:hover,
button.loading:hover {
  background: var(--disabled_background);
  color: var(--disabled_text);
  box-shadow: none;
}
.button.disabled:hover,
button.disabled:hover {
  cursor: default;
}
.button.loading:hover,
button.loading:hover {
  cursor: progress;
}

.next-arrow {
  margin-left: 0.5rem;
  position: relative;
  top: 1px;
}

input,
.radio-pill label.button {
  border: 1px solid var(--border);
  border-radius: var(--sq_border_radius);
}
input:active,
input:focus {
  border: 1px solid var(--secondary_accent);
}
/* 
.radio-pills .button.unselected{
  box-shadow:none;
  border:1px solid var(--border);
} */

.form-group {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
.form-group:last-of-type {
  margin-bottom: 2.5rem;
}

.validation-summary {
  border-top: 1px solid var(--error_border);
  border-bottom: 1px solid var(--error_border);
  background: var(--error_background);
  text-align: center;
}
.validation-summary {
  padding: 1rem;
  margin-bottom: 1rem;
  color: var(--error_text);
}
.validation-error {
  padding: 1rem 0 0 0;
  color: var(--error_text);
}
.validation-error {
  font-size: 16px;
  position: relative;
  top: -6px;
}

input[type="date"] {
  /* Sizing it down so the text doesn't overflow on firefox */
  font-size: 0.8em;
  padding: 0 5px;
  appearance: none;
  -webkit-appearance: none;
}

/* Hides scroll bar on select dropdown */
select::-webkit-scrollbar {
  width: 0 !important;
}
select {
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 100% 50%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}