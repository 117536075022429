.rsw-wrapper {
  min-height: 95% !important;
}
@keyframes stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes rainbow {
  0% {
    background-color: var(--red);
  }
  20% {
    background-color: var(--orange);
  }
  40% {
    background-color: var(--yellow);
  }
  60% {
    background-color: var(--green);
  }
  80% {
    background-color: var(--blue);
  }
  100% {
    background-color: var(--indigo);
  }
}
@keyframes grow {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

input[type="radio"]:focus:not(:checked) + label {
  outline: 1px solid #0b6ed2;
}

.wizard {
  background: var(--background_color);
}
.wizard:not(.embedded).step-wizard {
  padding-top: 15px;
}
.wizard:not(.embedded) .wizard-step-wrapper {
  padding-top: 5px;
}
.wizard:not(.embedded).wizard-step-Step02 .wizard-step-wrapper {
  padding-top: 40px;
}
.wizard:not(.embedded).wizard-step-Step01.step-wizard,
.wizard:not(.embedded).wizard-step-Final.step-wizard,
.wizard:not(.embedded).wizard-step-Step01 .wizard-step-wrapper,
.wizard:not(.embedded).wizard-step-Final .wizard-step-wrapper {
  padding-top: 0;
}
.wizard-step-wrapper {
  position: relative;
}

/* .wizard-step-wrapper{min-height:90vh;} /* Fixes wonkiness on mobile */
@media screen and (min-width: 576px) {
  .wizard-step-wrapper {
    min-height: initial;
  }
  .wizard:not(.embedded) .wizard-step-wrapper {
    padding-top: 25px;
  }
}

.wizard button.button {
  font-weight: 600;
}

.hero_img {
  max-height: 350px;
  padding: 0 2rem;
}

/* headers/layout */

.testTarget .big-label,
.testType01 .big-label,
.testType .big-label {
  text-align: center;
  padding: 0 16px;
  width: 100%;
  /* padding: .5rem 16px 1.5rem 16px; */
}

.requiredDetails .container,
.optionalDetails.wizard-step-wrapper,
.thankYou.wizard-step-wrapper .form-wrapper {
  max-width: var(--form-width);
}

/* Final step */
.thankYou .confirmation-banner {
  margin-bottom: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #e7f7ee;
  color: #0d6f0d;
  padding: 2rem 1rem 2.5rem 1rem;
}
.thankYou .confirmation-banner p {
  margin-bottom: 1rem;
  text-align: center;
}
/* .thankYou .confirmation-banner button{min-height:44px;} */
.thankYou .confirmation-banner button {
  margin: 1rem;
  max-width: 320px;
}
.thankYou h1,
.thankYou h2 {
  text-align: center;
  align-items: center;
}
.wizard .thankYou button.button {
  margin: 0;
}
.thankYou .accordion {
  margin: 1rem 0 2rem 0;
}
.thankYou .panel,
.thankYou .panel:first-of-type {
  border: 1px solid #ccc;
  border-radius: var(--sq_border_radius);
}
.thankYou button.panel__label {
  min-height: 44px;
}
.thankYou .panel__label {
  padding: 1rem 3rem 1rem 2rem;
}
.thankYou .panel__inner {
  padding: 0 2rem;
}
.thankYou .panel__content {
  padding: 1rem 0 2rem 0;
}
.thankYou .panel__content ul,
.thankYou .panel__content li:last-of-type {
  margin-bottom: 0;
}
/* .thankYou button.panel__label:focus{
    outline: 0;
    border:0;
} */
.results-module {
  padding: 3rem;
  margin: 1rem 0;
}
.results-module button,
.results-module .button {
  margin: 1rem 0;
}
.more-info {
  text-align: center;
}
.wtt-module img {
  max-width: 240px;
  margin-bottom: 1rem;
}
.partner-module .to-lower-case {
  font-weight: 700;
}
.wizard.embedded .wizard-step-wrapper.thankYou {
  padding-top: 0;
}

@media screen and (max-width: 767px) {
  /* bring partner module CTA above fold */
  .wizard.embedded .thankYou .confirmation-banner h2,
  .wizard.white-labeled .thankYou .confirmation-banner h2 {
    margin-bottom: 0;
  }
  .wizard.white-labeled .next-steps-module,
  .wizard.embedded .next-steps-module {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .thankYou.wizard-step-wrapper .form-wrapper {
    max-width: 1200px;
  }
  .thankYou .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .partner-module button,
  .partner-module .button {
    flex-direction: row;
  }
}

/* STEP 2: Test Results */
.wizard-step-wrapper.testResult {
  padding-top: 40px;
}

.wizard-step-wrapper.testResult #about-banner {
  margin-top: -55px;
}

.test-result-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center !important;
  gap: 28px;
}

/* On desktop, we add some margin to bump the 2nd and 3rd options to the second row */
@media screen and (min-width: 768px) {
  .test-result:first-child {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media screen and (min-width: 767px) {
  .test-result-options legend span {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0 20px;
  }
}
.language-es .test-result-options legend span {
  font-size: 0.85em;
}
@media screen and (max-width: 767px) {
  .language-es .test-result-options legend span {
    font-size: 0.75em;
    line-height: 1.3;
    top: 10px;
    padding: 0;
    display: block;
  }
}

.test-result {
  border: 3px solid var(--darkest_accent);
  border-radius: 20px;
  padding: 50px 20px 20px;
  position: relative;
  width: 460px;
}
.test-result.test-target-covid {
  border-color: var(--main_accent);
}

.test_results_buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  gap: 5px;
  margin-top: 40px; /* Corrects wrap of text on very narrow mobile devices */
}
@media screen and (min-width: 360px) {
  .test_results_buttons {
    flex-wrap: nowrap;
  }
}
.test_results_buttons .input-button {
  /* flex:1; */
  margin: 0 0.25rem;
}
.test_results_buttons .button {
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 0.75rem 2rem 0.75rem 0.75rem;
  width: 140px;
  margin: 0.25rem;
  font-weight: 600;
}

.test_results_buttons .button svg {
  width: 38.5px;
}
.test_results_buttons .button path {
  fill: white;
}
.test_results_buttons .button.active {
  background-color: var(--secondary_accent);
  color: var(--text_color);
}

.test_results_buttons .button.inactive path,
.test_results_buttons .button.active path {
  fill: var(--text_color);
}

.test_results_buttons .button.inactive {
  background-color: #ccc;
  color: var(--text_color);
}

.family-annotation {
  font-size: 14px;
}

@media screen and (min-width: 400px) {
  .test_results_buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
  }
  .test_results_buttons .input-button {
    /* flex:1; */
    margin: 0 0.25rem;
  }
  .test_results_buttons .button {
    display: flex;
    height: 60px;
    padding: 0.75rem 2rem 0.75rem 0.75rem;
    width: 155px;
    margin: 0;
  }
  .test_results_buttons .input-button img {
    margin: 0.25rem 0.5rem 0.25rem 0.25rem;
  }
}
@media screen and (min-width: 576px) {
  .test_results_buttons .input-button {
    margin: 0 0.25rem;
  }
  .test_results_buttons .button {
    display: flex;
    padding: 0.75rem 2rem 0.75rem 1rem;
    width: 190px;
    font-size: 20px;
  }
  .test_results_buttons .input-button img {
    margin: 0 1rem;
  }
}
@media screen and (min-width: 768px) {
  .testTarget .col-2-md {
    flex-direction: row-reverse;
  }
  .testTarget .big-label {
    font-size: 40px;
    margin-bottom: 1rem;
  }
  .test_results_buttons .button {
    font-size: 18px;
    width: 130px;
  }
  .test_results_buttons .input-button img {
    margin: 0 0.5rem 0 0;
  }
}
@media screen and (min-width: 992px) {
  .testTarget .big-label {
    font-size: 50px;
    margin-bottom: 2rem;
  }
  .test_results_buttons .input-button {
    margin: 0 0.5rem;
  }
  .test_results_buttons .button {
    font-size: 20px;
    width: 190px;
  }
  .test_results_buttons .input-button img {
    margin: 0 1rem;
  }
}

@media screen and (max-width: 767px) {
  .test-result-options {
    gap: 20px;
  }
  .test-result-options legend {
    float: left;
  }
  .test_results_buttons {
    clear: both;
  }
  .test-result {
    padding: 20px;
  }
}

/* STEP 1: Test target */

.testTarget .button {
  max-width: 350px;
  margin: 0 auto 15px;
}

/* STEP 2.1: Test type 01 */
.testType01.wizard-step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testbox-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: Center;
  color: var(--text_color);
}
.testbox-buttons .input-button {
  padding: 0.33rem;
  display: flex;
  justify-content: center;
}
/* .testbox-buttons .input-button:nth-of-type(odd){padding-right:.5rem;}
.testbox-buttons .input-button:nth-of-type(even){padding-left:.5rem;} */
.testbox-buttons .button {
  width: 180px;
  justify-content: space-between;
  height: auto;
  flex-direction: column;
  color: var(--dark-accent);
  margin: 0;
  padding: 0;
  background: white;
  border: 1px solid var(--border);
  gap: 0.5rem;
}
.testbox-buttons .button {
  padding: 0.5rem 0 0;
}

.testbox-buttons .test-name {
  padding: 0px 9px;
  font-size: 16px;
}

.testbox-buttons .button img {
  border-bottom-left-radius: var(--border_radius);
  border-bottom-right-radius: var(--border_radius);
}
@media screen and (min-width: 300px) {
  .testbox-buttons .input-button {
    width: 50%;
  }
}
@media screen and (min-width: 500px) {
  .testbox-buttons .input-button {
    width: initial;
  }
}

.show-more-button.button {
  justify-content: center;
}

/* STEP 2.2: Test type 
 */

.back-button {
  display: flex;
  align-items: center;
  top: -2px;
  justify-content: center;
  position: absolute;
  left: 0;
  color: var(--text_color);
  height: var(--input-height);
  width: var(--input-height);
  cursor: pointer;
  appearance: none;
  background: none;
  box-shadow: none;
  border: none;
  min-height: auto;
  /* display:none; */
}
/* to make room for back button when text is left-aligned */
.first-label {
  padding-left: 16px;
}
@media screen and (min-width: 480px) {
  .first-label {
    padding-left: 0;
  }
}

/* Step 3: Required details */
.selected-test-banner {
  background-color: var(--highlighted);
  text-align: center;
  font-weight: 500;
  padding: 12px;
  margin-top: -16px;
  margin-bottom: 15px;
  color: var(--text_color);
  flex-direction: column;
  border-radius: 0;
}

.embedded .selected-test-banner {
  margin-top: 0;
}

.selected-test-banner .link {
  color: var(--link_color);
}

.form-group.checkbox-fieldset {
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.form-group.checkbox-fieldset input {
  width: 28px;
  height: 28px;
}
.form-group.checkbox-fieldset input:focus + label {
  border: 0;
  outline: none;
}
.form-group.checkbox-fieldset .tooltip {
  margin-bottom: 0.5rem;
}

.requiredDetails .radioPills {
  display: flex;
  max-width: var(--form-width);
  align-items: center;
}

/* Step 4 */

.reminder .tooltip {
  margin-left: 5px;
}

.reminder-disclaimer {
  font-style: italic;
  margin-bottom: 0;
}

.radio-pills {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 0;
  flex-wrap: wrap;
}
.radio-pills .input-button {
  flex: 1 1;
  /* margin:0 .5rem 0 0; */
  /* margin:0 .25rem .25rem 0; */
}
.radio-pills .button {
  font-weight: normal;
  box-shadow: none;
  border: 1px solid var(--border);
  background: var(--white);
  color: var(--text_color);
  min-height: var(--input-height);
  height: 100%;
  margin: 0;
  line-height: 110%;
  padding: 0.5rem;
}
/* .radio-pills .button:hover {
  box-shadow:var(--box-shadow);
} */
.radio-pills input:hover + label {
  background-color: var(--highlighted);
}
.radio-pills input:checked + label {
  background-color: var(--secondary_accent);
  /* color: var(--white); */
  box-shadow: none;
}

/* @media screen and (min-width: 768px) { */
.radio-pills {
  max-width: var(--form-width);
}
.radio-pills .input-button {
  margin-right: -1px;
}
.radio-pills .button {
  border-radius: 0;
  box-shadow: none;
}
.radio-pills .input-button:first-of-type .button {
  border-top-left-radius: var(--sq_border_radius);
  border-bottom-left-radius: var(--sq_border_radius);
}
.radio-pills .input-button:last-of-type .button,
.radio-pills .button.last-pill {
  border-top-right-radius: var(--sq_border_radius);
  border-bottom-right-radius: var(--sq_border_radius);
}

.race .radio-pills .button,
.ethnicity .radio-pills .button {
  min-width: 150px;
  height: 100%;
}
.race .radio-pills {
  flex: 0 0 auto;
}
.race .radio-pills .input-button:first-of-type .button,
.ethnicity .radio-pills .input-button:first-of-type .button {
  border-top-left-radius: var(--sq_border_radius);
  border-top-right-radius: var(--sq_border_radius);
  border-bottom-left-radius: 0;
}

.race .radio-pills .input-button:last-of-type .button,
.ethnicity .radio-pills .input-button:last-of-type .button {
  border-bottom-left-radius: var(--sq_border_radius);
  border-bottom-right-radius: var(--sq_border_radius);
  border-top-right-radius: 0;
}

@media screen and (min-width: 351px) {
  .race .radio-pills .input-button:first-of-type .button {
    border-top-left-radius: var(--sq_border_radius);
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .race .radio-pills .input-button:nth-of-type(2) .button {
    border-top-right-radius: var(--sq_border_radius);
  }
  .race .radio-pills .input-button:nth-of-type(5) .button {
    border-bottom-left-radius: var(--sq_border_radius);
  }
  .race .radio-pills .input-button:last-of-type .button {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ethnicity .radio-pills .input-button:first-of-type .button {
    border-bottom-left-radius: var(--sq_border_radius);
    border-top-right-radius: 0;
  }
  .ethnicity .radio-pills .input-button:last-of-type .button {
    border-top-left-radius: 0;
    border-top-right-radius: var(--sq_border_radius);
    border-bottom-left-radius: 0;
  }
}

#phone {
  width: 100%;
}

.form-group .big-label {
  margin-bottom: 0.5rem;
}

.optional-header {
  text-align: center;
  margin-bottom: var(--header-margin);
}

.optional-header label {
  margin: 0 auto;
}

.optional-form {
  max-width: 800px;
}

.optional-form .form-group.reminder span {
  padding-bottom: 1rem;
}

.optional-header .big-label {
  margin-bottom: 0;
}

.learn-more {
  text-align: right;
  width: 100%;
  padding: 1rem;
}

@media screen and (min-width: 576px) {
  .testbox-buttons .input-button {
    min-width: 180px;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
  }
  /* .testbox-buttons .input-button:nth-of-type(odd){padding:0 .5rem;}
    .testbox-buttons .input-button:nth-of-type(even){padding:0 .5rem;} */

  /* .optional-header,.optional-header .big-label{text-align:left; } */
}

/* STEP 6: Optional Contact info */
#share-contact-q {
  margin-bottom: 20px;
}
#contactDateOfBirth {
  color: var(--text_color);
}
#contactDateOfBirth.not-set {
  color: var(--disabled_text);
}

.form-group.row.names {
  display: grid;
  grid-template-areas:
    "fnq mnq lnq"
    "fna mna lna";
  gap: 4px;
}

#first-name-q {
  grid-area: fnq;
}

#contactFirstName {
  grid-area: fna;
}

#middle-name-q {
  grid-area: mnq;
}

#contactMiddleName {
  grid-area: mna;
}

#last-name-q {
  grid-area: lnq;
}

#contactLastName {
  grid-area: lna;
}

.smaller-description {
  font-size: 0.8em;
  font-style: italic;
  margin-top: 5px;
}
.form-group.required label::after {
  content: "*";
  color: var(--error_text);
}
.form-group.error {
  color: var(--error_text);
}
.form-group.error input {
  border-color: var(--error_text);
  color: var(--error_text);
}

.form-group.row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 8px;
}

.form-group.row .form-group {
  display: block;
  margin-bottom: 0;
  flex: 1;
}

.form-group.row input {
  width: 100%;
  margin-bottom: 0;
}

#contactStreetAddress,
#contactEmail,
#contactState {
  width: 100%;
}

/* Styles for the Wizard when embedded or used in the Plugin  */
.wizard.embedded {
  overflow: hidden;
  min-height: calc(100vh - 118px);
}

.wizard.embedded h1,
.wizard.embedded h2,
.wizard.embedded h3,
.wizard.embedded h4,
.wizard.embedded h5,
.wizard.embedded h6,
.wizard.embedded .big-label {
  font-family: "Noto Sans", sans-serif;
}
/* .wizard.embedded h1,
.wizard.embedded .big-label {
  font-size: 20px;
} */

.wizard.embedded .wizard-step-wrapper {
  padding-top: 40px;
}

.wizard.embedded #about-banner {
  margin-top: -40px;
}
.next-steps-module {
  padding-bottom: 1rem;
}

@media screen and (max-width: 767px) {
  .wizard.embedded .testTarget .hero_img {
    margin-top: 20px;
  }

  .wizard.embedded .testTarget .container {
    flex-direction: column-reverse;
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .wizard.embedded .col-2 > div,
  .col-2-md > div {
    width: 100%;
  }
  .col-2-md > div:first-of-type.next-steps-module {
    padding-right: 2rem;
  }
  .results-right-column {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 355px) {
  .wizard.embedded .test_results_buttons .input-button img {
    display: none;
  }
  .wizard.embedded .test_results_buttons .input-button {
    width: 50%;
    margin: 0;
    padding: 1%;
    min-width: 70px;
  }
  .wizard.embedded .test_results_buttons .input-button .button {
    width: 100%;
    text-align: center;
    padding: 0;
    justify-content: center;
    margin: 0;
  }
}

.testing-journal {
  margin-bottom: 20px;
}
.testing-journal h2 {
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
}
.testing-journal h2:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  height: 3px;
  width: 30px;
  background: var(--main_accent);
  margin-top: 1rem;
}

.post-report-journal-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.testing-journal ol {
  list-style: none;
  margin-left: 0;
}
.testing-journal li {
  border-bottom: 1px solid var(--border);
  padding: 5px 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 600;
  gap: 15px;
}
.testing-journal li:last-child {
  border-bottom: 0;
}

.testing-journal .target {
  min-width: 70px;
}

.test-history .test {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.test-history .tests {
  flex: auto;
  display: flex;
  flex-direction: column;
}

.testing-journal .result.positive {
  color: var(--main_accent);
}
.testing-journal .result.negative {
  color: var(--green);
}
