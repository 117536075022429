/* Original Styles */
.nav-wrapper {
  border-bottom: 1px solid var(--border);
  position: relative;
}

.nav-container {
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 20px;
}

.flex-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-left-content {
  display: flex;
  gap: 20px;
  align-items: center;
  flex: 1 1 0%;
}

.nav-logo {
  border: 3px solid transparent;
  border-radius: 30px;
  display: inline-flex;
  cursor: pointer;
  padding: 0.5rem 0;
}

.nav-logo img {
  object-fit: contain;
  min-width: 40px;
  height: 34px;
  width: auto;
}

.nav-links ul {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0;
}

.nav-links.desktop {
  display: flex;
}

.nav-links li {
  list-style-type: none;
  margin: 0;
}

.whitelabel-name,
.nav-links a {
  font-family: var(--serif-font-family);
  font-weight: 700;
  text-decoration: none;
  color: var(--text_color);
  font-size: 18px;
  display: flex;
  position: relative;
}

.whitelabel-name {
  line-height: 120%;
  font-size: 1.5rem;
}

.nav-links .more-info-toggle,
.nav-links a {
  padding: 24px 0;
}

.nav-links .more-info-toggle::before,
.nav-links a::after {
  content: "";
  background-color: transparent;
  width: 30px;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.nav-wrapper.whitelabeled .nav-links.mobile .more-info-toggle::before,
.nav-wrapper.whitelabeled .nav-links.mobile a::after {
  left: 16px;
}

.nav-links .more-info-toggle:hover::before,
.nav-links a:hover::after {
  background-color: var(--main_accent);
}

.whitelabel-name:visited,
.nav-links a:visited {
  color: var(--text_color);
}

.mobile-nav {
  color: var(--text_color);
  text-decoration: none;
  order: 1;
}

button.mobile-nav-toggle {
  display: flex;
  color: var(--text-color);
  padding: 8px;
  border: 0;
  margin: 0;
  min-height: 44px;
}

button.mobile-nav-toggle svg {
  width: 28px;
  height: 28px;
}

.nav-wrapper.whitelabeled .nav-links.mobile,
.nav-links.mobile {
  position: absolute;
  background-color: white;
  transition-duration: 0.3s;
  overflow: hidden;
  top: 100%;
  left: 0;
  right: 0;
  height: 0vh;
  z-index: 100;
}

.nav-wrapper.whitelabeled .nav-links.mobile.is-visible,
.nav-links.mobile.is-visible {
  height: 100vh;
}

.nav-links.mobile ul {
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 0;
  max-width: 1280px;
  margin: 0 auto;
}

.nav-links.mobile li {
  width: 100%;
}

.nav-links.mobile a,
.nav-links.mobile .more-info-toggle {
  font-family: unset;
  font-size: 28px;
  font-weight: 400;
  padding: 12px 16px;
  line-height: 140%;
}

.nav-links.mobile ul.whitelabeled-nav-box {
  padding: 0.5rem;
  border-radius: 8px;
  background-color: var(--highlighted);
  margin: 0 auto;
}

.whitelabeled-nav-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whitelabeled-nav-header span {
  margin-right: -10px;
}

.nav-links .more-info {
  position: relative;
}

.nav-links .more-info-toggle {
  font-family: var(--serif-font-family);
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  color: var(--text_color);
  font-size: 18px;
  display: flex;
  position: relative;
  cursor: pointer;
  background: none;
  border-radius: 0;
  justify-content: flex-start;
  border: 0;
  margin: 0;
}

.nav-links .more-info-items {
  display: none; /* hidden by default */
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
  list-style: none;
  padding: 10px 0;
  z-index: 1000;
  width: auto;
  white-space: nowrap;
}

.nav-links .more-info-items.visible {
  display: block; /* show when clicked */
  border: 1px solid var(--border);
}

.nav-links .more-info-items li {
  padding: 5px 20px;
  text-align: left;
}

.nav-links .more-info-items li a {
  text-decoration: none;
  color: var(--text_color);
  display: block;
  padding: 12px 0;
  font-family: var(--serif-font-family);
  font-weight: 700;
  font-size: 18px;
}

@media (max-width: 1280px) {
  .nav-links .more-info-items {
    position: static;
    box-shadow: none;
    background-color: transparent;
    border: none !important;
  }

  .nav-links .more-info-items li a {
    padding: 10px 16px;
  }

  .nav-links.desktop {
    display: none;
  }

  .nav-logo {
    padding: 0;
  }
}

@media (min-width: 1281px) {
  .mobile-nav {
    padding: 0.8rem;
  }

  .mobile-nav:not(.show-always) {
    display: none;
  }
}

@media (min-width: 1024px) {
  .nav-container {
    max-width: 1280px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.nav-links .more-info-toggle .more-info-caret {
  font-size: 2.8rem;
  line-height: 140%;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .3s;
  transition-property: transform;
}

.nav-links .more-info-toggle.open .more-info-caret {
  transform: rotate(90deg);
}

.nav-wrapper.whitelabeled .nav-links .more-info-toggle {
  font-family: unset;
  font-size: 28px;
  font-weight: 400;
  padding: 12px 16px;
  line-height: 140%;
}

.nav-wrapper.whitelabeled .nav-links .more-info-items li a {
  padding: 10px 16px; /* Consistent padding */
}


.nav-wrapper.whitelabeled .nav-links .more-info-items {
  position: static;
  box-shadow: none;
  background-color: transparent;
  border: none !important;
}

.nav-wrapper.whitelabeled .nav-links .more-info-items li a {
  padding: 10px 16px;
}
