.partner-request {
  border: 1px solid #ddd;
  background: #eee;
  border-radius: 2px;
  padding: 3rem;
}

#make-my-test-count-plugin {
  font-size: 2em;
  margin: 40px auto;
}

@media screen and (max-width: 650px) {
  #make-my-test-count-plugin {
    font-size: 1.8em;
  }
}

.partner-value-props {
  flex-direction: row;
  display: flex;
  gap: 20px;
  margin-bottom: 70px;
}

.partner-value-prop {
  padding: 10px;
  width: 33.3%;
  text-align: center;
  background-color: var(--highlighted);
  border-radius: 5px;
}

.partner-value-prop h2 {
  margin: 10px auto;
}
.partner-value-prop p {
  font-size: 0.85em;
}

.partner-value-prop a {
  font-size: 0.85em;
}

.partner-value-prop-circle {
  background-color: var(--dark_accent);
  border-radius: 50%;
  padding-top: 40%;
  width: 40%;
  position: relative;
  margin: 0 auto;
}

.partner-value-prop img {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}

@media screen and (max-width: 650px) {
  .partner-value-props {
    flex-direction: column;
  }

  .partner-value-prop {
    width: 100%;
  }

  #make-my-test-count-plugin {
    font-size: 1.8em;
  }

  .partner-value-prop {
    display: flex;
    gap: 20px;
  }

  .partner-value-prop-circle {
    min-width: 100px;
    height: 100px;
    align-self: center;
    padding-top: 0;
    width: auto;
  }

  .partner-value-prop-text {
    text-align: left;
  }
}

.plugin-choices {
  padding-top: 70px;
}
.plugin-choices header {
  max-width: 640px;
  margin-bottom: 30px;
}

.form-submit-wrapper {
  text-align: center;
}

.submit-error {
  background-color: var(--error_background);
  border: 1px solid var(--error_border);
  color: var(--error_text);
  padding: 4px 8px;
  margin-bottom: 10px;
  border-radius: 2px;
}

#partner-request-form input {
  width: 100%;
}
.partner-request header .big-label {
  margin-top: 0;
}
#partner-request-form .error {
  color: var(--error_text);
}
#partner-request-form .error input {
  background-color: var(--error_background);
  border-color: var(--error_border);
  color: var(--error_text);
}

#partner-request-form .button-error {
  background-color: var(--error_text);
  border-color: var(--error_text);
  color: white;
}

#theme-logo {
  padding: 0;
  border: 0;
  border-radius: 0;
}

.logo-label small {
  font-family: "Noto sans", sans-serif;
  font-size: 50%;
}

.logo-image-container {
  position: relative;
  display: inline-block;
}

.logo-image-preview {
  max-width: 100px;
  max-height: 100px;
  margin: 15px 0 0;
  border: 1px solid var(--darkest_accent);
  display: block;
}

.logo-image-delete {
  margin-top: 10px;
}

.white-label-results {
  margin: 20px 0;
}

.white-label-results a {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 2px 4px;
  border: 1px solid #ddd;
  background-color: #eee;
  color: var(--link_color);
  border-radius: 4px;
}

.qr-code-container {
  text-align: center;
}
