@import url("base/reset.css");

:root {
  font-family: "Noto Sans", serif;
  --serif-font-family: "Bitter", serif;
  --main_accent: #e11681;
  --secondary_accent: #52eaff;
  --dark_accent: #0058b7;
  --darkest_accent: #21355b;
  --highlighted: #e4f5f9;
  --highlighted_green: #d9f6e6;
  --green: #199d19;
  /* UI colors */
  --background_color: #f8fbfc;
  --text_color: #00426b;
  --border: #d9d9d9;
  --link_color: #0058b7;
  /* Only used in validation messages: */
  --error_background: #ffedf6;
  --error_border: #ffaed8;
  --error_text: #cf006d;
  --disabled_background: #dde5ed;
  --disabled_text: #7d858e;
  --border_radius: 30px;
  --sq_border_radius: 10px;
  --border-width: 3px;
  --white: #fff;
  --back-button: #a5a5a5;
  --input-height: 45px;
  --button-height: 55px; /*changes with media queries? */
  --header-margin: 0.75rem;
  --form-width: 400px;

  /*OLD*/
  --nav_color: #5d6e81;
  --activelink: #00518d;
  --box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  --transition: all 0.5s ease;
}
@media screen and (min-width: 576px) {
  :root {
    --form-width: 530px;
  }
}

html {
  scroll-behavior: smooth;
}
/* @import 'src/partials/header.scss';
@import 'src/partials/wizard/wizard.scss';
@import 'src/partials/wizard/progress.scss'; */

/* @import url("partials/header.scss");
@import url("partials/wizard/progress.scss"); */
@import url("../partials/wizard/wizard.css");

@import url("base/typ.css");
@import url("base/forms.css");
@import url("base/layout.css");

@import url("partials/language-switcher.css");
@import url("partials/nav.css");
@import url("partials/footer.css");
@import url("partials/icon-cards.css");
@import url("partials/modal.css");
@import url("partials/accordion.css");

@import url("pages/home.css");

.dark_accent {
  color: var(--dark_accent);
}

.no-wrap {
  white-space:nowrap;
}
