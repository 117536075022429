/* Tooltip container */
.tooltip {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: var(--white);
  color: var(--text_color);
  border: 1px solid var(--border);
  text-align: left;
  border-radius: 6px;
  position: absolute;
  transform: translate(-50%, 0);
  z-index: 1;
  min-width: 250px;
  padding: 1rem;
  bottom: 100%;
  left: 50%;
  min-height: 35px;
  font-size: 0.9em;
}

.tooltip .tooltiptext a {
  transition: none;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip.align-right .tooltiptext {
  width: 300px;
  transform: translate(-90%, 0);
}
.tooltip .tooltiptext::before,
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  border-width: 5px;
  width: 5px;
  border-style: solid;
  border-color: var(--white) transparent transparent transparent;
}
.tooltip.align-right .tooltiptext::before,
.tooltip.align-right .tooltiptext::after {
  left: 90%;
}

.tooltip .tooltiptext::before {
  margin-left: -7px;
  border-width: 7px;
  border-color: var(--border) transparent transparent transparent;
}
.tooltip .tooltiptext::after {
  margin-left: -5px;
  border-color: var(--white) transparent transparent transparent;
}
