.sample-link > div {
  margin: 60px auto;
  background-color: var(--highlighted);
  border-radius: 5px;
  padding: 30px;
}

.sample-link .back-button-container {
  max-width: 250px;
}
