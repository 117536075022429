.main-navigation {
  padding: 16px 12px;
}

.plugin-test {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
}

.plugin-test code,
.plugin-test pre {
  background-color: #ddd;
  border: 1px solid var(--border);
  border-radius: 3px;
  padding: 2px 4px;
  font-family: monospace;
  font-size: 0.8em;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.plugin-test-section button {
  width: 200px;
}

.plugin-page-content {
  display: flex;
  gap: 20px;
}
.plugin-page-content > section {
  width: 50%;
  padding-top: 0;
}

.plugin-page-content > aside {
  width: 50%;
  padding-top: 10px;
}

#partner-request-form textarea {
  width: 100%;
  min-height: 200px;
  border-radius: 20px;
  padding: 10px;
}
.option-module {
  margin-bottom: 2rem;
}
.option-header {
  text-align: center;
}
.option-header .button {
  max-width: 360px;
  margin: 0 auto;
}
.option-header .button:hover {
  color: white;
}
.option-header img {
  border: 1px solid var(--border);
  margin: 2rem 0;
}

.plugin-instructions li {
  margin-bottom: 3rem;
}
.plugin-instructions pre {
  margin: 1rem 0;
}
.col-2 > div.option-module,
.col-2-md > div.option-module {
  padding: 3rem;
}

@media screen and (min-width: 768px) {
  .col-2 > div.option-module,
  .col-2-md > div.option-module {
    width: 49%;
  }
}
